import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../../components/seo"
import quote from "../../images/double-quotes-l.png"
import { Link } from "gatsby"
import "swiper/swiper-bundle.min.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Thumbs,
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from "swiper"
SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade, Thumbs])

export default function index() {
  return (
    <div className="font-property">
      <Seo title="Landing Page" />
      <Header />
      <Introduction />
      <ShowCase />
      <HowItsWork />
      <Service />
      <CallBack />
      <Testimonial />
    </div>
  )
}

const Header = ({ images }) => {
  return (
    <>
      <div className="grid text-black items-stretch container mx-auto p-2 lg:p-8 relative">
        <div className="h-[85vh] lg:h-[90vh] flex items-start col-start-1 row-start-1 z-20 relative">
          <div className="container mx-auto text-center mt-4">
            <Link to="/">
              <span className="sr-only">Ibiza High Life</span>
              <StaticImage
                height={45}
                className="md:h-10  w-auto h-12"
                src="../../../static/img/logo.svg"
                alt="Ibiza High Life"
              />
            </Link>
            <h2 className="text-3xl md:text-5xl lg:text-7xl font-serif w-full max-w-2xl mx-auto mt-2">
              Curated Luxury Travel Experience
            </h2>
            <p className="py-2 text-xl md:text-2xl font-medium text-center font-property">
              Creating your dream Ibiza holiday
            </p>
          </div>
        </div>
        <div className="grid col-start-1 row-start-1 z-30 self-end">
          <button className="w-10 h-16 rounded-full outline-none focus:outline-none border mx-auto">
            <svg
              className="h-4 w-4 fill-current text-white animate-bounce mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="12" />
            </svg>
          </button>
          <p className="font-light text-white pb-6 mt-8 text-center ">
            Scroll down
          </p>
          <div className="flex space-x-3 md:space-x-6 xl:space-x-10 ml-auto absolute right-0 mr-0 md:mr-6 xl:mr-24">
            <button className="prev w-12 h-12 outline-none focus:outline-none">
              <svg
                className="transform text-white fill-current rotate-180 mx-auto"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
              </svg>
            </button>
            <button className="next w-12 h-12 outline-none focus:outline-none">
              <svg
                className="mx-auto text-white fill-current"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="absolute inset-0 m-auto p-2 lg:p-8">
          <div>
            <Slider images={images} />
          </div>
        </div>
      </div>
    </>
  )
}

const Slider = ({ images }) => {
  return (
    <div className="flex container mx-auto">
      <Swiper
        effect="fade"
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        loop={true}
        loopedSlides={5}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        scrollbar={{ draggable: true }}
      >
        <SwiperSlide>
          <StaticImage
            className="object-cover h-[85vh] lg:h-[90vh] w-full"
            src="../../../static/img/header_imgae.png"
            alt="Ibiza High Life"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

const Introduction = () => {
  return (
    <div className="py-12 -mt-16 bg-gray-50">
      <div className="w-full max-w-xl mx-auto py-12 ">
        <p className="tracking-widest uppercase text-center py-6 font-medium">
          Well Hello There
        </p>
        <h3 className="text-3xl  font-serif mx-auto text-center py-6">
          An exclusive collection of Ibiza's finest villas.
        </h3>
        <p className="text-center font-medium leading-8">
          With a handpicked portfolio of stunning villas and an intimate
          knowledge of The White Isle, our team is on hand to ensure that every
          aspect of your vacation is nothing less than you deserve.
        </p>
      </div>
    </div>
  )
}

const ShowCase = () => {
  return (
    <div className="py-12">
      <div className="container mx-auto px-2 lg:px-8 ">
        <h2 className="pb-6">Our Showcase Villas</h2>
        <Swiper
          navigation={{
            prevEl: ".cprev",
            nextEl: ".cnext",
          }}
          pagination={{
            el: ".pagination",
            clickable: true,
            renderBullet: (index, className) => {
              return (
                '<span style="width: 50px; height:4px; border-radius: 5px " class="mx-2 bg-gray-300 ' +
                className +
                '"> </span>'
              )
            },
          }}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            345: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1560: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            2560: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          slidesPerView={3}
        >
          <SwiperSlide>
            <div className="grid h-card-xl">
              <div>
                <StaticImage
                  src="../../../static/img/Villa-Arabella@2x.png"
                  className="object-cover h-56 lg:h-64 w-full"
                  alt="Villa Arabella"
                />
                {/* <GatsbyImage
                  image={property.images[0].asset.gatsbyImageData}
                  className="h-card-lg w-full"
                /> */}
              </div>
              <div>
                <div className="p-4">
                  <h3 className="  text-3xl font-light text-black py-3 ">
                    Villa Arabella
                  </h3>
                  <span className="font-sans text-7xl text-gray-200 py-2">
                    01 {/* {"0" + (key + 1)} */}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid h-card-xl">
              <div>
                <StaticImage
                  src="../../../static/img/Villa-Delphine@2x.png"
                  className="object-cover h-56 lg:h-64 w-full"
                  alt="Villa Delphine"
                />
                {/* <GatsbyImage
                  image={property.images[0].asset.gatsbyImageData}
                  className="h-card-lg w-full"
                /> */}
              </div>
              <div className="">
                <div className="p-4">
                  <h3 className="  text-3xl font-light text-black py-3 ">
                    Villa Delphine
                  </h3>
                  <span className="font-sans text-7xl text-gray-200 py-2">
                    02 {/* {"0" + (key + 1)} */}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid h-card-xl">
              <div>
                <StaticImage
                  src="../../../static/img/Villa-Marcella@2x.png"
                  className="object-cover h-56 lg:h-64 w-full"
                  alt="Villa Marcella"
                />
                {/* <GatsbyImage
                  image={property.images[0].asset.gatsbyImageData}
                  className="h-card-lg w-full"
                /> */}
              </div>
              <div className="">
                <div className="p-4">
                  <h3 className="  text-3xl font-light text-black py-3 ">
                    Villa Marcella
                  </h3>
                  <span className="font-sans text-7xl text-gray-200 py-2">
                    03 {/* {"0" + (key + 1)} */}
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="pagination py-6 flex justify-center"></div>
        </Swiper>
      </div>
    </div>
  )
}

const HowItsWork = () => {
  return (
    <div className="container px-2 lg:px-8 mx-auto py-8 lg:py-12">
      <p className="w-11/12 lg:w-4/5 mx-auto text-base md:text-3xl lg:text-4xl text-center font-sans">
        Our in-depth knowledge of island allow our travel experts to curate a
        list of villas tailored to your requirements.
      </p>
      <h3 className="py-6 text-2xl md:text-4xl font-serif text-center">
        How it works
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-20 items-center justify-items-center">
        <div className="p-8 bg-gray-200 w-64">
          <p className="text-gray-500 text-3xl text-center">1</p>
          <h4 className="py-4 text-2xl font-bold text-center">
            Tell us about your requirement
          </h4>
          <p className="text-xs text-center">
            Dates, guests, budget and preference.
          </p>
        </div>
        <div className="p-8 bg-gray-200 w-64">
          <p className="text-gray-500 text-3xl text-center">2</p>
          <h4 className="py-4 text-2xl font-bold text-center">
            Recieve a customised collection of villas
          </h4>
          <p className="text-xs text-center">Share with Friend and family.</p>
        </div>
        <div className="p-8 bg-gray-200 w-64">
          <p className="text-gray-500 text-3xl text-center">3</p>
          <h4 className="py-4 text-2xl font-bold text-center">
            Choose your villa and complete booking
          </h4>
          <p className="text-xs text-center">
            Fast, secure, and online process.
          </p>
        </div>
      </div>
    </div>
  )
}

const Service = () => {
  return (
    <div className="py-6 lg:py-12 w-11/12 mx-auto">
      <div className="flex justify-center pb-6">
        <Link
          to="/actions/landing/personalised-villas/request"
          className="py-4 px-12 bg-black text-white text-center text-sm uppercase font-bold"
        >
          Find my dream villa
        </Link>
      </div>
      <p className="tracking-widest uppercase text-sm font-medium text-center pt-6 pb-3">
        More than Villa rental
      </p>
      <h3 className="text-3xl md:text-4xl font-serif text-center w-full xl:w-1/3 mx-auto py-6">
        Customise your stay with The High Life Concierge
      </h3>
      <p className="py-3 max-w-3xl text-center mx-auto text-lg">
        Whatever your requirements, once you have booked your villa our
        concierge will be available to help plan every detail of your stay. From
        the finest chefs to boat charters, luxury cars, health &amp; wellness,
        sound systems and even BBQ’s, we are happy to help with every aspect of
        your stay.
      </p>
      <div className="container mx-auto px-2 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-12 pt-10">
          <div>
            <StaticImage
              src="../../../static/img/boats@2x.png"
              className="h-64 w-full object-cover"
              alt="Boats"
            />
            <h4 className="p-2 text-center uppercase text-sm tracking-widest font-bold">
              Boats
            </h4>
          </div>
          <div>
            <StaticImage
              src="../../../static/img/cars@2x.png"
              className="h-64 w-full object-cover"
              alt="Cars"
            />
            <h4 className="p-2 text-center uppercase text-sm tracking-widest font-bold">
              Cars
            </h4>
          </div>
          <div>
            <StaticImage
              src="../../../static/img/in-villa@2x.png"
              className="h-64 w-full object-cover"
              alt="Villa"
            />
            <h4 className="p-2 text-center uppercase text-sm tracking-widest font-bold">
              Villas
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}
const CallBack = () => {
  return (
    <div className="max-w-7xl w-full mx-auto bg-gray-100 px-5 py-12 my-6">
      <h3 className="text-3xl md:text-4xl font-serif text-center">
        So what are you waiting for?
      </h3>
      <p className="text-base font-sans w-full md:w-1/2 xl:w-1/3 font-medium mx-auto text-center py-2">
        Explore our huge range of villas and experiences and start designing
        your holidat today
      </p>
      <div className="grid gap-4 md:gap-0 md:flex justify-around py-4">
        <Link
          to="/actions/landing/personalised-villas/request"
          className="py-4 px-12 bg-black text-white text-center text-sm uppercase font-bold"
        >
          Find my dream villa
        </Link>
        <Link
          to="/villas"
          className="py-4 px-12 bg-transparent border-2 border-black text-black text-center text-sm uppercase font-bold"
        >
          search villas now
        </Link>
      </div>
    </div>
  )
}

const user = [
  {
    name: "Sarah Pike",
    position: "",
    feedback:
      "Ibiza High Life are always my go to in ibiza, anything at all I ever need is arranged as if by magic. If you want the best then only use Ibiza High Life.",
  },
  {
    name: "Danni Harris",
    position: "Head of Sales",
    feedback:
      "We were introduced to Ibiza High Life through a mutual friend and I can honestly say, our wedding would have been nothing without him. They are quick, responsive and honest; making you feel how every holiday should be - happy and relaxed. He helped with all of our queries to ensure our wedding, honeymoon and overall holiday was second to none. His team are equally as polite and brilliant ensuring the best service all round.",
  },
  {
    name: "N. Little",
    position:
      "Senior Advisor to PPR Estates and Smith & Williamson Corporate Finance, UK",
    feedback:
      'Ibiza High Life is an extremely efficient company that has totally fulfilled ( and surpassed ) all our requirements relating to our villa in Ibiza and is described by a Russian friend of mine as " super, super efficient and extremely friendly."...I would reinforce these comments fully and also say , that Ibiza High Life makes holidays on Ibiza great fun and has the ability to gain access to top tables in every place we or our friends wanted to go.....Invaluable !',
  },
  {
    name: "Edward",
    position: "Private Equity Fund Manager - London, UK",
    feedback:
      "Ibiza High Life takes care of all of my Ibiza needs every year and without fail. Impeccable service, professional attitude and good people.",
  },
  {
    name: "Ed Viering",
    position: "Director General, Spain",
    feedback:
      "I would highly recommend your services! You treated us very well over the past 10 years already in Ibiza with all we needed. If we need transport, dinner locations or party tips, you always follow up asap. We look forward to the new season so we can meet again soon !",
  },
  {
    name: "Reshad Hossenally",
    position: "Managing Director, Ticket Arena & Event Genius",
    feedback:
      "Exceptional access, service and hospitality are all standard with the Ibiza Highlife team. For a stress free holiday just call Ibiza High Life.",
  },
  {
    name: "Barrett Masso",
    position: "Real Estate Developer, California",
    feedback:
      "When heading to Ibiza, the call to IHL is the only call I make. After years of working with the IHL team, I just let them know we are on our way and they take care of everything. IHL delivers a level service that I have yet to experience anywhere else in the world. Every time we visit Ibiza, the IHL team manage to exceed expectations.",
  },
  {
    name: "William Charles Berger III",
    position: "Wealth Management Merrill Lynch, LA",
    feedback:
      "Ibiza high life are the best! I was super excited to go for the first time 2 years ago and let’s just say they rolled out the red carpet for us! Everything from the second I landed to the resort and to the clubs/restaurants was top tier platinum service! Although the relationship started off as a business relationship it ended as a friendship and that’s something rare when it comes to VIP Host in my expert opinion! Excited to have them set up our IBIZA trio every summer until the day I die!",
  },
]

const Testimonial = () => {
  return (
    <div className=" max-w-screen-2xl mx-auto py-6 bg-gray-100">
      <div className="text-center py-4">
        <p className="uppercase tracking-widest font-medium">Our Customers</p>
        <h3 className="text-2xl md:text-4xl font-serif py-6">
          What they say about us
        </h3>
      </div>
      <div className="grid grid-cols-12 gap-6 w-11/12 xl:w-5/6 mx-auto">
        <div className=" row-start-2 md:row-start-1 col-span-12 md:col-span-4">
          <div className="w-1/2 md:w-full">
            <ul className="flex md:grid gap-0 md:gap-4 pagination-testi space-x-4 md:space-x-0"></ul>
          </div>
        </div>
        <div className="row-start-1 col-span-12 md:col-span-8">
          <img src={quote} alt="quote" className="opacity-25" />
          <Swiper
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              el: ".pagination-testi",
              clickable: true,
              renderBullet: (index, className) => {
                return (
                  '<li style="width: 100%; border-radius: 0 " class=" py-0 md:py-10 xl:py-12 px-0 md:px-4 text-white bg-gray-300 ' +
                  className +
                  '">' +
                  '<div class=" hidden md:flex  items-center space-x-2 xl:space-x-5 md:-mt-5 xl:-mt-6"><div><h4 class="font-serif truncate text-xl font-medium">' +
                  user[index].name +
                  '</h4><p class="text-xs">' +
                  user[index].position +
                  "</p> </div>  </div>" +
                  "</li>"
                )
              },
            }}
            slidesPerView={1}
          >
            {user.map((feed, key) => {
              return (
                <SwiperSlide key={key}>
                  <div className="px-0 md:px-6">
                    <p className="text-lg md:text-3xl font-property leading-8">
                      {feed.feedback}
                    </p>
                    <div className="grid justify-items-end md:hidden">
                      <h4 className="font-bold text-xl font-serif">
                        {feed.name}
                      </h4>
                      <p className="font-light text-xs font-property">
                        {feed.position}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
